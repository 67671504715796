import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";

render(
    <BrowserRouter>
        <ScrollToTop />
        <HomePage />
    </BrowserRouter>
    , document.getElementById("root")
);