import "../assets/scss/Main.scss";
import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Index = lazy(() => import("./Index"));
const WhatsApp = lazy(() => import("./WhatsApp"));

const HomePage = () => {
    return (
        <Routes>
            <Route path="/" element={<React.Suspense fallback={null}><Index /></React.Suspense>} />
            <Route path="/:slugCode" element={<React.Suspense fallback={null}><WhatsApp /></React.Suspense>} />
        </Routes>
    );
};

export default HomePage;